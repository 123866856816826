import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getDatabase, ref, set, get, child} from "firebase/database";
import Appointment from "./appointment";
import {handleSuccess} from "./PhysicianPortal";

let db = null;
let analytics = null;
export function Init() {
    const firebaseConfig = {
        apiKey: "AIzaSyBzpPHOXKjHVSmlDV5Kyikxof_12oCzz5M",
        authDomain: "seg3125-lab8-a6b72.firebaseapp.com",
        databaseURL: "https://seg3125-lab8-a6b72-default-rtdb.firebaseio.com",
        projectId: "seg3125-lab8-a6b72",
        storageBucket: "seg3125-lab8-a6b72.appspot.com",
        messagingSenderId: "240520971617",
        appId: "1:240520971617:web:9f87f529b2ee9a95c47120",
        measurementId: "G-Z8ZHTWM5KV"
    };

// Initialize Firebase
    const app = initializeApp(firebaseConfig);
    db = getDatabase(app);
    analytics = getAnalytics(app);
}

export function verifyPasswd(user, password, handler) {
    let dbRef = ref(db);
    let passwd = null;
    let path = "Physicians/"+user+"/passwd";
    get(child(dbRef, path)).then((snapshot) => {
        if(snapshot.exists()) {
            passwd = snapshot.val();
            if(password === passwd) {
                handler(true);
            }
            else {
                handler(false, 1);
            }
        }
        else {
            console.log("No data available");
            handler(false, 2);
        }
    }).catch((error) => {
        console.error(error);
        handler(false);
    });
}

export function retrieveAppointmentInfo(appointmentNumber, handler) {
    let dbRef = ref(db);
    let path = "Appointments/"+appointmentNumber;
    get(child(dbRef, path)).then((snapshot) => {
        if(snapshot.exists()) {
            handler(snapshot.val());
        }
        else {
            console.log("No data available");
            handler(null);
        }
    }).catch((error) => {
        console.error(error);
        handler(null);
    });
}

export function uploadResult(appointmentNumber, result) {
    let dbRef = ref(db);
    let path = "Results/"+appointmentNumber;
    set(child(dbRef, path), result).then(r => {
        handleSuccess();
    });
}

export function retrieveResult(appointmentNumber, handler) {
    let dbRef = ref(db);
    let path = "Results/"+appointmentNumber;
    get(child(dbRef, path)).then((snapshot) => {
        if(snapshot.exists()) {
            handler(snapshot.val());
        }
        else {
            console.log("No data available");
            handler(null);
        }
    }).catch((error) => {
        console.error(error);
        handler(null);
    });
}

export function verifyPhone(phone) {
    const phone_regex = new RegExp("^[0-9]{3}-[0-9]{3}-[0-9]{4}$");
    const phone_without_dash_regex = new RegExp("^[0-9]{10}$");
    return phone_regex.test(phone) || phone_without_dash_regex.test(phone);
}

export function verifyEmail(email) {
    const email_regex = new RegExp("^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$");
    return email_regex.test(email);
}

export function getToday() {
    const date = new Date();
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    if(month < 10){
        month = "0" + month;
    }
    if(day < 10){
        day = "0" + day;
    }
    const today = year + "-" + month + "-" + day;
    return today;
}

export function verifyDate(d, mode) {
    const today = getToday();
    if(mode === 1){
        return d <= today;
    }
    if(mode === 2){
        return d > today;
    }
}

export function sendAppointment(name, birth, email, phone, gender, appDate, message) {
    const today = Date.now();
    if(db === null) {
        alert("Database not initialized");
        return;
    }
    Appointment.handleNameOnHover();
    Appointment.handlePhoneOnHover();
    Appointment.handleEmailOnHover();
    Appointment.handleBirthDateOnHover();
    Appointment.handleAppDateOnHover();

    set(ref(db, 'Appointments/' + today), {
        name: name,
        birth: birth,
        email: email,
        phone: phone,
        gender: gender,
        appDate: appDate,
        message: message
    }).then(r => {
        Appointment.handleSuccess(today);

    }).catch(e => {
        console.log(e);
        alert("Failed to create appointment");
        return;
    });
}