import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';

function PureHealthDiagnostics() {
    return (
        <>
        <div>
            <h1 className="display-1 text-center">Welcome to Pure Health Diagnostics</h1>
            <p>
                At Pure Health Diagnostics, we are dedicated to providing high-quality
                health check-ups to help you stay on top of your health. Our services
                include comprehensive medical check-ups, laboratory tests, and
                diagnostic imaging, all delivered by experienced medical professionals.
            </p>

            <h2>Services</h2>
            <p>Our services include:</p>
            <ul>
                <li>Physicals</li>
                <li>Drug Testing</li>
                <li>Immunizations</li>
                <li>Flu Shots</li>
                <li>Lab Services</li>
            </ul>

            <h2>Locations</h2>
            <p>Our locations include:</p>
            <ul>
                <li>1200 9th St, Greeley, CO 80631</li>
                <li>800 37th Ave, Greeley, CO 80634</li>
                <li>3333 8th St, Greeley, CO 80631</li>
            </ul>

            <h2>Contact Us</h2>
            <p>Our phone number is: 970-353-7000</p>
            <p>Our email is: xxx@xxx.com</p>
        </div>
        </>
    );
}

export default PureHealthDiagnostics;
