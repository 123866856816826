import {Route, Routes} from "react-router-dom";
import Home from "./home";
import Appointment from "./appointment"
import Services from "./PureHealthDiagnostics";
import React from "react";
import Result from "./Result";
import PhysicianLogin from "./PhysicianLogin";
import PhysicianPortal from "./PhysicianPortal";

const routes = () => {
    return (
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/Services" element={<Services />} />
            <Route exact path="/appointment" element={<Appointment />} />
            <Route exact path="/result" element={<Result />} />
            <Route exact path="/physician_login" element={<PhysicianLogin />} />
            <Route exact path="/physician_portal" element={<PhysicianPortal />} />
            <Route element={<Home />} />
        </Routes>
    );
}

export default routes;