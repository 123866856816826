import React, {Component} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {getToday, retrieveAppointmentInfo, uploadResult} from "./databaseOperation";

const today = getToday();
let appointmentID = 0;

function begin() {
    appointmentID = document.getElementById("appointmentID").value;
    let exam = document.getElementById("exam");
    retrieveAppointmentInfo(appointmentID, (data) => {
        if(data != null) {
            var name = data["name"];
            var exam_date = data["appDate"];
            document.getElementById("patientName").value = name;
            document.getElementById("examDate").value = exam_date;
            document.getElementById("appointmentID2").value = appointmentID;
            exam.hidden = false;
        }
        else {
            alert("Appointment ID not found. Please try again.");
            exam.hidden = true;
        }
    });
}

function generateReport() {
    let weight = document.getElementById("weight").value;
    let height = document.getElementById("height").value;
    let bloodPressure = document.getElementById("bloodPressure").value;
    let heartbeat = document.getElementById("heartbeat").value;
    let breathRate = document.getElementById("breathRate").value;
    let allergies = document.getElementById("allergies").value;
    let medications = document.getElementById("medications").value;
    let limbs = document.getElementById("limbs").value;
    let chest = document.getElementById("chest").value;
    let abdomen = document.getElementById("abdomen").value;
    let notes = document.getElementById("notes").value;
    let result = {
        "weight": weight,
        "height": height,
        "bloodPressure": bloodPressure,
        "heartbeat": heartbeat,
        "breathRate": breathRate,
        "allergies": allergies,
        "medications": medications,
        "limbs": limbs,
        "chest": chest,
        "abdomen": abdomen,
        "notes": notes
    };
    uploadResult(appointmentID, result);
}

function reset() {
    document.getElementById("exam").hidden = true;
    document.getElementById("weight").value = "";
    document.getElementById("height").value = "";
    document.getElementById("bloodPressure").value = "";
    document.getElementById("heartbeat").value = "";
    document.getElementById("breathRate").value = "";
    document.getElementById("allergies").value = "";
    document.getElementById("medications").value = "";
    document.getElementById("limbs").value = "";
    document.getElementById("chest").value = "";
    document.getElementById("abdomen").value = "";
    document.getElementById("notes").value = "";
    document.getElementById("appointmentID").value = "";
}

export function handleSuccess() {
    alert("Exam submitted successfully!");
    reset();
}

class PhysicianPortal extends Component {
    render() {
        return (
            <div>
                <h1 className="display-1 text-center">Physician Portal</h1>
                <div className="container justify-content-center">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6">
                            <form>
                                <div className="form-group">
                                    <label htmlFor="appointmentID">Enter Appointment Number to begin:</label>
                                    <input type="number" className="form-control" id="appointmentID" aria-describedby="Enter appointment ID" />
                                    <div className="invalid-feedback" id="err_a"></div>
                                </div>
                                <br/>
                                <button type="button" className="btn btn-primary" onClick={begin}>Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
                <br/>
                <div className="container" id="exam" hidden="true">
                    <div className="form-group">
                        <label htmlFor="examDate">Exam Date:</label>
                        <input type="date" className="form-control" id="examDate" value={today} disabled='disabled'/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="appointmentID">Appointment Number:</label>
                        <input type="number" className="form-control" id="appointmentID2" disabled='disabled'/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="patientName">Patient Name:</label>
                        <input type="text" className="form-control" id="patientName" value="John Doe" disabled='disabled'/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="height">Height:</label>
                        <input type="number" className="form-control" id="height"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="weight">Weight:</label>
                        <input type="number" className="form-control" id="weight"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="bloodPressure">Blood Pressure:</label>
                        <input type="text" className="form-control" id="bloodPressure"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="heartbeat">Heartbeat Rate:</label>
                        <input type="number" className="form-control" id="heartbeat"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="breathRate">Breath Rate:</label>
                        <input type="number" className="form-control" id="breathRate"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="allergies">Allergies:</label>
                        <input type="text" className="form-control" id="allergies"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="medications">Medications:</label>
                        <input type="text" className="form-control" id="medications"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="limbs">Limbs:</label>
                        <input type="text" className="form-control" id="limbs"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="abdomen">Abdomen:</label>
                        <input type="text" className="form-control" id="abdomen"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="chest">Chest:</label>
                        <input type="text" className="form-control" id="chest"/>
                    </div>
                    <div className="form-group">
                        <label htmlFor="notes">Additional Notes:</label>
                        <textarea type="text" className="form-control form-text" id="notes"/>
                    </div>
                    <button type="button" className="btn btn-primary" onClick={generateReport}>Submit</button>
                    <button type="button" className="btn btn-primary" onClick={reset}>Reset</button>
                </div>
            </div>
        );
    }


}



export default PhysicianPortal;