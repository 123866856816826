import logo from './logo.svg';
import React, {Fragment} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import {Link} from "react-router-dom";

function navbar() {
    return (
        <Fragment>
            <Navbar sticky="top" bg="light">
                <Container>
                    <Navbar.Brand href="/"><img src={logo} width="100" height="100" className="d-inline-block align-top" alt="logo"/></Navbar.Brand>
                    <Nav className="justify-content-end">
                        <Nav.Link as={Link} to='/'>Home</Nav.Link>
                        <Nav.Link as={Link} to='/Services'>Services</Nav.Link>
                        <Nav.Link as={Link} to="/appointment">Make Appointment</Nav.Link>
                        <Nav.Link as={Link} to="/result">🔒Patient Portal</Nav.Link>
                        <Nav.Link as={Link} to="/physician_login">🔒Physician Portal</Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
        </Fragment>

    );
}

export default navbar;