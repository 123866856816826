import Navbar from "./navbar";
import Routes from "./routes";
import {Init} from "./databaseOperation";
import React, { Component } from "react";
import {BrowserRouter} from "react-router-dom";

class App extends Component {
    render () {
        return (
            <BrowserRouter>
                <Navbar />
                <Routes />
                <Init />
            </BrowserRouter>

        );

    }

}

export default App;