import logo from './Logo.png';
import React, {Component, Fragment} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Link} from "react-router-dom";

class Home extends Component {
    render() {
        return (
            <>
                <Fragment>
                    <div className="container my-5">
                        <div className="row">
                            <div className="col-md-8">
                                <h1>Welcome to Pure Health Diagnostics</h1>
                                <p>We offer a range of high quality medical check-up services to ensure your health.</p>
                                <Link to="/Services" className="btn btn-primary">Learn More ➡</Link>
                            </div>
                            <div className="col-md-4">
                                <img src={logo} alt="logo" className="img-fluid rounded" />
                            </div>
                        </div>
                    </div>
                </Fragment>
            </>
        );
    }
}

export default Home;