import React, {Component} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {verifyPasswd} from "./databaseOperation";
import md5 from "js-md5";
class PhysicianLogin extends Component {
    handleLogin() {
        let username = document.getElementById("username").value;
        let passwd = md5(document.getElementById("passwd").value);
        verifyPasswd(username, passwd, PhysicianLogin.handlerAfterLogin)
    }

    static handlerAfterLogin(flag, err) {
        if(!flag) {
            if(err === 1){
                let err_p = document.getElementById("err_p");
                let p = document.getElementById("passwd");
                p.classList.remove("is-valid");
                p.classList.add("is-invalid");
                err_p.innerHTML = "Invalid password";
            }
            if(err === 2){
                let err_u = document.getElementById("err_u");
                let err_p = document.getElementById("err_p");
                let u = document.getElementById("username");
                let p = document.getElementById("passwd");
                u.classList.remove("is-valid");
                p.classList.remove("is-valid");
                u.classList.add("is-invalid");
                p.classList.add("is-invalid");
                err_u.innerHTML = "Invalid username";
                err_p.innerHTML = "Invalid password";
            }

        }
        else {
            let err_u = document.getElementById("err_u");
            let err_p = document.getElementById("err_p");
            let u = document.getElementById("username");
            let p = document.getElementById("passwd");
            u.classList.remove("is-invalid");
            p.classList.remove("is-invalid");
            u.classList.add("is-valid");
            p.classList.add("is-valid");
            err_u.innerHTML = "";
            err_p.innerHTML = "";
            window.location.href = "/physician_portal";
        }
    }

    render() {
        return (
            <div>
                <h1 className="display-1 text-center">Physician Login</h1>
                <div className="container justify-content-center">
                    <div className="row justify-content-center">
                        <div className="col-12 col-md-6">
                            <form>
                                <div className="form-group">
                                    <label htmlFor="username">User Name:</label>
                                    <input type="text" className="form-control" id="username" aria-describedby="username" />
                                    <div id="err_u" className="invalid-feedback"></div>
                                </div>
                                <div className="form-group">
                                    <label htmlFor="passwd">Password:</label>
                                    <input type="password" className="form-control" id="passwd" />
                                    <div id="err_p" className="invalid-feedback"></div>
                                </div>
                                <br/>
                                <button type="button" className="btn btn-primary" onClick={this.handleLogin}>Submit</button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }


}

export default PhysicianLogin;