import React, {Component} from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {retrieveResult} from "./databaseOperation";

let appointmentID = 0;
function resultReturn() {
    appointmentID = document.getElementById("appointmentID").value;
    retrieveResult(appointmentID, (result) => {
        if(result != null) {
            let JSON_result = {
                "id": appointmentID,
                "name": result["name"],
                "bmi": calculateBMI(result["weight"], result["height"]),
                "weight": result["weight"],
                "height": result["height"],
                "heartBeat": result["heartbeat"],
                "limbs": result["limbs"],
                "abdomen": result["abdomen"],
                "additionalNotes": result["notes"]
            };
            DisplayResult(JSON_result);
        }
        else {
            document.getElementById("result_region").hidden = false;
            document.getElementById("result_region").innerHTML = "No result found for this appointment ID. Please try again.";
        }
    });

}

function calculateBMI(weight, height) {
    return  weight / ((height/100) * (height/100));
}

function DisplayResult(JSON_result) {
    document.getElementById("result_region").innerHTML = JSON.stringify(JSON_result);
    document.getElementById("result_region").hidden = false;
}


class Result extends Component {
    /* static DisplayResult(props) {
        const patient = JSON.parse(props.patient);
        return (
            <>
                <br/>
                <h1>Search Results for Patients</h1>
                <ul>
                    <li>Patient ID: {patient["id"]}</li>
                    <li>
                        <h3>{patient["name"]}</h3>
                        <p>{patient["bmi"]} BMI</p>
                        <p>{patient["weight"]} Weight</p>
                        <p>{patient["height"]} Height</p>
                        <p>{patient["heartBeat"]} Heart Beat</p>
                        <p>{patient["limbs"]} Limbs</p>
                        <p>{patient["abdomen"]} Abdomen</p>
                        <p>{patient["additionalNotes"]} Additional Notes</p>
                    </li>
                </ul>
            </>
        );
    }*/
    render() {
        return (
            <>
                <div>
                    <h1 className="display-1 text-center">Query Result</h1>
                    <div className="container justify-content-center">
                        <div className="row justify-content-center">
                            <div className="col-12 col-md-6">
                                <form>
                                    <div className="form-group">
                                        <label htmlFor="appointmentID">Enter Appointment Number to begin:</label>
                                        <input type="number" className="form-control" id="appointmentID" aria-describedby="Enter appointment ID" />
                                        <div className="invalid-feedback" id="err_a"></div>
                                    </div>
                                    <br/>
                                    <button type="button" className="btn btn-primary" onClick={resultReturn}>Submit</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="result_region" hidden="true">
                </div>
            </>
        );
    }

}

export default Result;
